import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {QueryClient,QueryClientProvider} from '@tanstack/react-query'
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
//import { withTranslation } from "react-i18next";

const queryClient = new QueryClient()

function App(props) {  
 
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);

  useEffect(()=>
  {
    onRouteChanged();
  },[isFullPageLayout, props]);

  let navbarComponent = !isFullPageLayout ? <Navbar/> : '';
  let sidebarComponent = !isFullPageLayout ? <Sidebar/> : '';
  let footerComponent = !isFullPageLayout ? <Footer/> : '';
  
  const onRouteChanged = () => {
    console.log("ROUTE CHANGED");
    //const { i18n } = this.props;
    const body = document.querySelector('body');
    if(props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      //i18n.changeLanguage('ar');
    }
    else {
      body.classList.remove('rtl')
      //i18n.changeLanguage('en');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/home','/user-pages/login-1', '/user-pages/register-1', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (props.location.pathname === fullPageLayoutRoutes[i]) {
        setIsFullPageLayout(true);
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        setIsFullPageLayout(false);
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }
   
    return (
      <QueryClientProvider client={queryClient}>
      <div className="container-scroller">
        { navbarComponent }
        <div className="container-fluid page-body-wrapper">
          { sidebarComponent }
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes/>
            </div>
            { footerComponent }
          </div>
        </div>
      </div>
      </QueryClientProvider>
    );

}
//export default withTranslation() (withRouter(App));

export default (withRouter(App));
